import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'

const LifeLogPage = () => {
    return (
        <Layout>
        <Head title="Life log" />
        <h1>Life log</h1>
        <p>Goals, experiences, and dreams. List also coming soon. :)</p>
        </Layout>
    )
}

export default LifeLogPage